
    .quick-add-deal-container {
        padding-top: 5px;
        .section-header-container {
            padding: 2px 0;
            border-bottom: 1px solid #dbdbdb;
            text-align: left;
            .section-header-title {
                font-weight: 700;
                letter-spacing: 1.1px;
                color: #a0a0a0;
                font-size: 12px;
            }
        }
        .form-field-helper-text{
            margin: 0;
            text-align: right;
        }
        .section-detail-container {
            margin: 15px 0px;
        }
        .each-field-title {
            text-align: left;
            margin: 0;
            font-size: 12px;
        }
        #input-box {
            font-size: 13px;
    
            text-align: left;
        }
    }
    