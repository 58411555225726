.team-board{
    // display: flex;
    width: 100%;
    // background: #ff888214 !important;
    // padding-top: 50px;
    .team-board-menu{
        min-width: 280px;
    }
    .divider-title{
        text-align: left;
        padding: 10px 0 0 10px;
    }
    .selectedOption{
        width: -webkit-fill-available;
        // background: #f0f0f0;
    }
    .settings-title-container{
        text-align: left;
    padding: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    .settings-title{
        font-size: 17px;
    font-weight: 600;
    }
    }
    .selected-value{
        background: #ccc;
    }
    .settings-fab-icon{
        width: 45px;
        height: 45px;
        position: fixed;
    z-index: 2;
    right: 20px;
    bottom: 80px;
    background: #786178;
    }
    
}