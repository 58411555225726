.custom-date-selector-wrapper{
    width: 100% !important;
    .custom-date-selector {
        display: flex !important;
      }
}

//   .react-datepicker__tab-loop {
//     display: inline-block !important;
//     z-index: 9999 !important;
//   }
//   .react-datepicker-popper[data-placement^="bottom"] {
//     margin-top: -10px !important;
//   }
//   .react-datepicker__header--time {
//     display: none !important;
//   }

.form-field-input{
    width: 100% !important;
    font-size: 13px !important;
            text-align: left;
}