.error-code{
    font-size: 150px;
    font-weight: 900;
    margin: 0;
    color: #ccc;
}
.not-found-msg{
    font-weight: 700;
    font-size: 18px;

}
.not-found-sub-title{
    font-size: 12px;
    color: #c7c7c7;
}