.add-notes-page{
    text-align: left;
    .deal-name{
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        color: #2c76d4;
        margin-bottom: 20px;
        word-break:break-all;
    }
    .title{
        font-size: 15px;
    color: #bfbfbf;
    font-weight: 500;
    margin: 5px 0;
    }
    .Value{
        margin-top: 5px;
        letter-spacing: 1px;
        font-size: 15px;
    }
}