.deal-overview{
    text-align: left;
    padding: 20px;
    padding-top: 0;

    .deal-name{
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        color: #2c76d4;
        word-break:break-all;
    }
    .title{
        font-size: 15px;
    color: #a2a2a2;
    font-weight: 500;
    margin: 5px 0;
    }
    .Value{
        margin-top: 5px;
        letter-spacing: 1px;
        font-size: 15px;
        word-break:break-all;
    }
}