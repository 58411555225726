.fleft{
    float:left !important;
}
.fright{
    float:right !important;
}
.pL0{
    padding-left: 0 !important;
}

.pR0{
    padding-right: 0 !important;
}
.pT0{
    padding-top: 0 !important;
}
.pB0{
    padding-bottom: 0 !important;
}
.pL5{
    padding-left: 5px !important;
}

.pR5{
    padding-right: 5px !important;
}
.pT5{
    padding-top: 5px !important;
}
.pB5{
    padding-bottom: 5px !important;
}
.pL10{
    padding-left: 10px !important;
}

.pR10{
    padding-right: 10px !important;
}
.pT10{
    padding-top: 10px !important;
}
.pB10{
    padding-bottom: 10px !important;
}

.pL15{
    padding-left: 15px !important;
}

.pR15{
    padding-right: 15px !important;
}
.pT15{
    padding-top: 15px !important;
}
.pB15{
    padding-bottom: 15px !important;
}

.pL20{
    padding-left: 20px !important;
}

.pR20{
    padding-right: 20px !important;
}
.pT20{
    padding-top: 20px !important;
}
.pB20{
    padding-bottom: 20px !important;
}
.mAuto{
    margin:auto !important;
}

.mL0{
    margin-left: 0 !important;
}

.mR0{
    margin-right: 0 !important;
}
.mT0{
    margin-top: 0 !important;
}
.mB0{
    margin-bottom: 0 !important;
}
.mL5{
    margin-left: 5px !important;
}

.mR5{
    margin-right: 5px !important;
}
.mT5{
    margin-top: 5px !important;
}
.mB5{
    margin-bottom: 5px !important;
}
.mL10{
    margin-left: 10px !important;
}

.mR10{
    margin-right: 10px !important;
}
.mT10{
    margin-top: 10px !important;
}
.mB10{
    margin-bottom: 10px !important;
}

.mL15{
    margin-left: 15px !important;
}

.mR15{
    margin-right: 15px !important;
}
.mT15{
    margin-top: 15px !important;
}
.mB15{
    margin-bottom: 15px !important;
}

.mL20{
    margin-left: 20px !important;
}

.mR20{
    margin-right: 20px !important;
}
.mT20{
    margin-top: 20px !important;
}
.mB20{
    margin-bottom: 20px !important;
}



.p5{
    padding: 5px !important;
}
.p10{
    padding: 10px !important;
}
.p15{
    padding: 15px !important;
}
.p20{
    padding: 20px !important;
}


.m5{
    margin: 5px !important;
}
.m10{
    margin: 10px !important;
}
.m15{
    margin: 15px !important;
}
.m20{
    margin: 20px !important;
}
