.login-page{
  .signin-title{
    color: #bcbcbc;
    font-size: 25px;
    font-weight: 600;
  }
    .header {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      color:white;
      font-size:14px;
      background-color: #336599;
      display: flex;

    }
    .icons,.waveText{
      font-weight: 900;
      font-size: 20px;
      color:white;
    }
    .divClass{
      display:inline-block;
      padding:0;
    }
  .loginButton{
    width:100%;
    background-color:#7e6a80 !important;
    border:#7e6a80 !important;
    transition: 0.4s;
    &:hover{
      background-color: #543855 !important
      
    }


  }
  .helpText{
    color:#336599;
    font-size:13px;
  }
  .login-container{
    padding-top:50px;
  }
  .fundwaveLogo{
    height:100%;
    align-self: center;
    margin: auto;
    // filter: invert(0) brightness(100);
}
.google-sign-container{
  display:flex;
}
.sign-in-text{
  align-self: center;
}
 .Toastify__toast--success {
  background-image: none;
  background: #449d44;
  color: white;
}
.Toastify__toast--error {
  background-image: none;
  background: #d9534f !important;
  color: white;
}
}