.card-container{
    padding: 15px 20px 10px 20px;
    // border-bottom: 0.5px solid #f2eded;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
    &:hover{
        background: #2c76d4;
        .deal-title{
            color:white
        }
        .deal-value{
            color:white
        }
    }
    .deal-title{
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        word-break:break-all;
    }
    .deal-value{
        text-align: left;
        font-size: 14px;
        color: #ccc;
        margin: 5px 0;
    }
}