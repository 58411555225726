.team-board-container {
    // padding: 20px;
    text-align: left;
    .add-domain-button {
        margin-left: 20px;
    }

    .team-container {
        margin-bottom: 10px;
        // display: inline-block;
        // text-align: left;
        .each-team {
            // display: inline-flex;
            // padding: 10px 20px 20px 10px;
            // margin: 10px;
            // margin-left: 0px;
            // text-align: left;
            // background: rgba(44, 119, 212, 0.74);
            // width: 100px;
            // min-height: 35px;
            font-size: 15px;
            word-break: break-all;
            padding: 20px;
            border-bottom: 1px solid #e6e6e6;
            box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);

            &:hover {
                cursor: pointer;
                color: white;
                background: #2c77d4;
            }
        }
        .team-name {
            font-size: 17px;
            color: white;
        }
    }
    .each-domain {
        // padding: 20px;
        .domain-name {
            font-weight: 500;
            font-size: 17px;
            color: #2c77d4;
            text-align: left;
            margin-top: 30px;
            margin-bottom: 5px;
            padding-left: 20px;
            
        }
        &:first-child{
            .domain-name {
                margin-top: 0px !important;

            }
        }
        
        .team-name {
            font-size: 16px;
            font-weight: 600;
        }
    }
}
