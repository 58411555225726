.header{
  background: transparent;
  margin-bottom: 25px !important;
display: flex;
align-items: center;
  // align-items: center;
  //   float: right;
  //   border-radius: 50%;
  // // padding: 10px;
  // display:flex;
  &:hover{
    // background: #0078d447;
    // border-radius: 50% 0 0 50%;
}
.header-title{

  padding: 0px;
  text-align: left;
  font-weight: 600;
  padding-left: 20px;
  align-items: center;
  color: gray;

}
.navigation-button{
  width: 50%;
  background: white;
  color: #939393;
  border-radius: 0;
  border: 1px solid #d9d9d9;
  box-shadow: inset 1px 1px 11px -6px;
  height: 40px;
  &:hover{
    background-color: #eee;
  }


}
.user-image-section{
  padding: 0;
  align-items: center;
  display: flex;
  margin: auto !important;
  justify-content: flex-end;
  padding: 4px;

  &:hover{
    background-color: #eee;
  }
}
.showBoarder{
  border: 1px solid #ccc;

}
  .fundwaveLogo{
    height:30px
  }

  .user-dropdown-arrow{
    font-size: 10px !important;
    margin-left: 5px;
    margin-right: 5px;
  }
  .signOutLogo{
    color: white;
    align-self: center;
    margin-left: auto;
  }

}

.divClass{
  box-shadow: 2px 4px 15px -8px;
  position: absolute;
  z-index: 9999;
  background: white;
  right: 0;
    top: 50px;
}

.dropdown-menu{
  // right: 0 !important;
  //   left: auto;
    list-style-type: none;
    padding: 0;

}
#dropdownMenu1{
  background: none;
    border: none;
}
.userList{
  margin: 0 15px;
  padding: 10px;
  border-bottom: 1px solid #e0dede;
    display: inline-flex;
    .details-user{
      align-self: center;
    }
    .userName{
      color:#3f3e3e;
      font-size:14px;
      margin:0;
      text-align: left
    }
    .userEmail{
      margin:0;
      color:#848484
    }
}

.userList-values:last-child{
  padding: 10px;
  margin: 0 15px;
  border-bottom: none;

}

.userList-values-container{
  .userList-values{
    align-items: center;
    display: flex;
  }
  .list-Values{
    color:#3f3e3e;
      font-size:14px;
      margin:0;
      display: inline-flex;
      align-items: center;

  }
}
.userList-values-container:hover{
  background-color: #0078d4;
  .list-Values{
    color:white;
    //

  }
}